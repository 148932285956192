import React from "react";
import CustomChoiceDropdown from "./CustomChoiceDropdown";

class DietaryForm extends React.Component {
  render() {
    const { formData, handleInputChange } =
      this.props; // Destructure props


    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="dietary">Do you have any dietary restrictions?</label>
            <input
              type="text"
              className="form-control"
              id="dietary"
              placeholder="Your dietary restrictions"
              value={formData.dietary}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default DietaryForm;
