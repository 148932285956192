import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from 'react';

import BuyTicketsComponent from './components/BuyTicketsComponent';
import AuthorizationWrapper from './components/AuthorizationComponent';
import SameDayRegistrationComponent from './components/SameDayRegistration/RegistrationComponent';
import YoungProfessionalBuyTicketsComponent from './components/YoungProfessionalBuyTicketsComponent'


function App() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/sponsorship') {
      const redirectUrl = window.location.href.includes('test')
        ? 'https://test.alternative-tickets.bigsistersbc.ca'
        : 'https://alternative-tickets.bigsistersbc.ca';
      window.location.href = redirectUrl;
    }
  }, [navigate]);


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BuyTicketsComponent />} />
        <Route path="/young-professionals" element={<YoungProfessionalBuyTicketsComponent />} />
        <Route path="/young-professional" element={<YoungProfessionalBuyTicketsComponent />} />
        <Route path="/exclusive" element={<YoungProfessionalBuyTicketsComponent />} />
        <Route path="/register-now" element={<SameDayRegistrationComponent />} />
        <Route path="/register/:url_query" element={<AuthorizationWrapper />} />
        <Route path="*" element={<BuyTicketsComponent />} /> {/* Fallback route */}

      </Routes>
    </div>
  );
}

export default App;
