import React from "react";
import Form from "react-bootstrap/Form";
import '../assets/css/ClassificationForm.css';


class ClassificationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appealOptions: [
        "Unsolicited Gifts",
        "United Way Direct Donor",
        "Online donations received",
        "Monthly Donors",
        "In Honour Of",
        "Giving Tuesday",
        "Canada Summer Jobs Funding",
        "2025 Luminary Award Soiree Sponsor",
        "2024 Luminary Award Soiree Sponsorship",
        "2024 Vancouver Aboriginal Family Services",
        "2024 Spring Lunch Ticket Purchase",
        "2024 Spring Lunch Sponsorship",
        "2024 Spring Lunch Sponsor-a-Match",
        "2024 Spring Lunch Raffle",
        "2024 Solicited Major Gift",
        "2024 Soiree LAS Auction Donation",
        "2024 Planned Giving Recruitment Event - Sponsor",
        "2024 Planned Giving Recruitment Event",
        "2024 Municipal Grant",
        "2024 Misc Third Party Events",
        "2024 Luminary Award Soiree Sponsorship",
        "2024 Little Sisters' Scholarship Donation",
        "2024 LAS Ticket Purchase",
        "2024 LAS Table Purchase",
        "2024 LAS Sponsor a Match",
        "2024 LAS Raffle",
        "2024 LAS Auction Item Purchase",
        "2024 GrapeJuice Ticket Purchase",
        "2024 GrapeJuice Sponsor a Match",
        "2024 GrapeJuice Cash Sponsor",
        "2024 GrapeJuice Auction Purchase",
        "2024 Family/Individual Foundation Grant",
        "2024 Family Services of Greater Vancouver",
        "2024 End of Year Campaign",
        "2024 Corporate Grant",
        "2024 Corporate Donation",
        "2024 Corp Donations-Employee's Volunteer Hours",
        "2024 Company's Matching Program Donations",
        "2023 Vancouver Aboriginal Family Services",
        "2023 Spring Lunch Ticket Purchase",
        "2023 Spring Lunch Sponsorship",
        "2023 Spring Lunch Sponsor-a-Match",
        "2023 Spring Lunch Raffle",
        "2023 Solicited Major Gift",
        "2023 Soiree LAS Auction Donation",
        "2023 Planned Giving Recruitment Event - Sponsor",
        "2023 Planned Giving Recruitment Event",
        "2023 Municipal Grant",
        "2023 Misc Third Party Events",
        "2023 Luminary Award Soiree Sponsorship",
        "2023 Little Sisters' Scholarship Donation",
        "2023 LAS Ticket Purchase",
        "2023 LAS Table Purchase",
        "2023 LAS Sponsor a Match",
        "2023 LAS Raffle",
        "2023 LAS Auction Item Purchase",
        "2023 GrapeJuice Ticket Purchase",
        "2023 GrapeJuice Sponsor a Match",
        "2023 GrapeJuice Cash Sponsor",
        "2023 GrapeJuice Auction Purchase",
        "2023 Family/Individual Foundation Grant",
        "2023 Family Services of Greater Vancouver",
        "2023 End of Year Campaign",
        "2023 Corporate Grant",
        "2023 Corporate Donation",
        "2023 Corp Donations-Employee's Volunteer Hours",
        "2023 Company's Matching Program Donations",
        "2022 Vancouver Aboriginal Family Services",
        "2022 Spring Lunch Sponsorship",
        "2022 Spring Lunch Sponsor-a-Match",
        "2022 Solicited Major Gift",
        "2022 Planned Giving Recruitment Event",
        "2022 Municipal Grant",
        "2022 Misc Third Party Events",
        "2022 MegaBike",
        "2022 LAS Ticket Purchase",
        "2022 LAS Table Purchase",
        "2022 LAS Sponsor a Match",
        "2022 LAS Sponsor",
        "2022 LAS Raffle",
        "2022 LAS Auction Item Purchase",
        "2022 GrapeJuice Sponsor a Match",
        "2022 GrapeJuice Cash Sponsor",
        "2022 Future You Camp Sponsor",
        "2022 Family/Individual Foundation Grant",
        "2022 Family Services of Greater Vancouver",
        "2022 End of Year Campaign",
        "2022 Corporate Grant",
        "2022 Corporate Donation",
        "2022 Company's Matching Program Donations",
        "2022 Canada Recovery Hiring Program",
      ],
      campaignOptions: [
        "Luminary Award Soiree",
        "Annual/Monthly",
        "Third Party Events",
        "Spring Lunch",
        "GrapeJuice",
        "MegaBike",
        "unsol",
        "Grants",
        "Government",
        "Corporate",
        "Future You Camp",
        "Planned Giving",
        "Major Gifts",
      ],
      fundOptions: [
        "Undesignated",
        "Events",
        "Big Sisters Program",
        "Study Buddy Program",
        "Funds deposited to Gaming",
        "Go Girls! Healthy Bodies, Healthy Minds Program",
        "Federal Government Wage Subsidies",
        "Bliss Fund",
        "Endowment",
        "Bright Futures Education Fund",
      ],
    };
  }

  render() {
    const { handleInputChange, formData } = this.props;
    const dropdownStyle = { textAlign: "center" };

    return (
      <div className="classification">

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="fund">Fund</label>
            <div className="dropdown input-group">
              <Form.Control
                id="fund"
                as="select"
                value={formData.fund}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a fund &#x2015;
                </option>
                {this.state.fundOptions.map((fund, index) => (
                  <>
                    <option key={fund} value={fund}>
                      {fund}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="appeal">Appeal</label>
            <div className="dropdown input-group">
              <Form.Control
                id="appeal"
                as="select"
                value={formData.appeal}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose an appeal &#x2015;
                </option>
                {this.state.appealOptions.map((appeal, index) => (
                  <>
                    <option key={appeal} value={appeal}>
                      {appeal}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="campaign">Campaign</label>
            <div className="dropdown input-group">
              <Form.Control
                id="campaign"
                as="select"
                value={formData.campaign}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a campaign &#x2015;
                </option>
                {this.state.campaignOptions.map((campaign, index) => (
                  <>
                    <option key={campaign} value={campaign}>
                      {campaign}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default ClassificationForm;
