import getTicketMode from './ticketService'

const earlyBirdTicketOptions = [{ value: "ticket", label: "Early Bird Ticket", cost: 400 },
{ value: "table", label: "Table", cost: 4000 },
{ value: "corporate", label: "Corporate Table", cost: 5000 }];

const regularBirdTicketOptions = [{ value: "regular-ticket", label: "Ticket", cost: 450 },
{ value: "regular-table", label: "Table", cost: 4500 },
{ value: "regular-corporate", label: "Corporate Table", cost: 5000 },
{ value: "regular-ambassador", label: "Ambassador Table", cost: 10000 }];

const getTicketOptions = async () => {
    const mode = await getTicketMode();

    if (mode === "EARLY") {
        return earlyBirdTicketOptions;
    }

    return regularBirdTicketOptions;
};

export default getTicketOptions;
