import React, { Component } from "react";
import RegistrationComponent from "./RegistrationComponent";
import { useParams } from 'react-router-dom';
import AuthorizationForm from "./AuthorizationForm";


function AuthorizationWrapper() {
    const { url_query } = useParams();
  
    return <AuthorizationComponent url_query={url_query} />;
  }

class AuthorizationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: true
    };
  }

  componentDidMount() {
  }

  authenticate = () => {
    this.setState({isAuthenticated: true});
  }



  render() {
    return (
        <>
        { this.state.isAuthenticated ? 
            <RegistrationComponent
            url_query={this.props.url_query}
        />
      : <AuthorizationForm url_query={this.props.url_query} authenticate={this.authenticate} />
      }

        </>


    );
  }


}

export default AuthorizationWrapper;
