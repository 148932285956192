import React from "react";
import "../assets/css/QuantitySelector.css";

class QuantitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        intervalId: null,
        editing: false,
        currentValue: "",
        error: ""
    };
}
  
    handleIncrement = () => {
      const currentValue = this.props.option.value;
      const newValue = currentValue + 1;
      const ticketQuantityOptions = this.props.choiceOptions;
      const matchingOption = ticketQuantityOptions.find(option => option.value === newValue);

      if (matchingOption) {
        this.props.handleChoiceUpdate(matchingOption, "selectedTicketQuantityOption");
      }
    };
  
    handleDecrement = () => {
      const currentValue = this.props.option.value;
      const newValue = currentValue - 1;
      const ticketQuantityOptions = this.props.choiceOptions;
      const matchingOption = ticketQuantityOptions.find(option => option.value === newValue);

      if (matchingOption) {
        this.props.handleChoiceUpdate(matchingOption, "selectedTicketQuantityOption");
      }
    };
  
    handleInputChange = (event) => {
      const newValue = parseInt(event.target.value, 10);

      if (!isNaN(newValue)) {
        this.setState({currentValue: newValue})


      }
    };

    handleBlur = (e) => {
      const newValue = this.state.currentValue;

      const ticketQuantityOptions = this.props.choiceOptions;
      const matchingOption = ticketQuantityOptions.find(option => option.value === newValue);

      if (matchingOption) {
        this.props.handleChoiceUpdate(matchingOption, "selectedTicketQuantityOption");
        this.setState({error: ""});
      } else {
        this.setState({error: "Please enter a valid ticket quantity"});
      }


      this.setState({editing: false, currentValue: ""});
    }

    getCurrentQuantity = () => {
      if (this.state.editing) {
        return this.state.currentValue;
      }
      
      return this.props.option.value;
    }

    render() {
      const {
        label,
        identifier,
        outerClass,
        isRequired,
        option
      } = this.props;
  
      return (
        <div className={outerClass ? outerClass : "col-lg-6"}>
          <div className="form-group">
            <label htmlFor={identifier}>
              {label}
              {isRequired && <span className="required"> *</span>}
            </label>

            <div className="quantity-control">
              <button
                type="button"
                className="btn btn-outline-secondary btn-left"
                aria-label="Decrement quantity"
                onClick={this.handleDecrement}
              >
                -
              </button>
              <input
                type="number"
                id={identifier}
                className="form-control quantity-input no-spinner"
                value={this.getCurrentQuantity()}
                onFocus={(e) => this.setState({editing: true})}
                onBlur={this.handleBlur}
                onChange={this.handleInputChange}
              />
              <button
                type="button"
                className="btn btn-outline-secondary btn-right"
                aria-label="Increment quantity"
                onClick={this.handleIncrement}
              >
                +
              </button>
            </div>
            {this.state.error && <p style={{color: "red"}}>{this.state.error}</p>}
          </div>
        </div>
      );
    }
  }
  
  export default QuantitySelector;