import React from "react";
import SingleChoiceDropdown from "./SingleChoiceDropdown";


class SponsorshipTicketNameForm extends React.Component {
  render() {
    const { formData, handleInputChange, toggleValue, creditCardSectionTitle, setCreditCard } =
      this.props; // Destructure props

    const lowerCaseHref = window.location.href.toLowerCase();

    return (
      <>

        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="firstName">
              First Name 
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="Your First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="lastName">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Your Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <SingleChoiceDropdown
          value={formData.organization}
          toggleValue={(e) => toggleValue(e, "organization")}
          label="Are the ticket(s) being purchased on behalf of an organization?"
          identifier="organization"
          outerClass="col-md-12"
          isRequired={true}
        />

        {formData.organization && (
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="organizationName">
                Organization Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="organizationName"
                placeholder="Organization Name"
                value={formData.organizationName}
                onChange={handleInputChange}
                required={formData.organization}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SponsorshipTicketNameForm;