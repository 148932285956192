import React, { useState, useEffect, useRef } from "react";
import { initialState, monthlyInitialState } from "../constants";
import { useLocation } from "react-router-dom";
import SingleAttendeeForm from "./SingleAttendeeForm";

const RegistrationForm = ({ updateIsLoading, attendees, fetchData, toast }) => {
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [activeAttendeeForm, setActiveAttendeeForm] = useState(-1);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);
  };

  const handleRegister = (e, index) => {
    e.preventDefault();

    setActiveAttendeeForm(index);
  };

  const areAllAttendeesRegistered = () => {
    return attendees.every((attendee) => attendee.email);
  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}  style={{ minHeight: '60vh' }}>

          <div className="row  text-light">
            <SingleAttendeeForm
                  setActiveAttendeeForm={setActiveAttendeeForm}
                  toast={toast}
                  fetchData={fetchData}
                  updateIsLoading={updateIsLoading}
                  attendees={attendees}
                />
          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationForm;