import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import VirtualTerminalForm from "./VirtualTerminalForm";
import CobsCampaignForm from "./CobsCampaignForm";
import CampaignForm from "./CampaignForm";
import BookCampaignForm from "./BookCampaignForm";
import BuyTicketsForm from "./BuyTicketsForm";
import SarahAndCamBuyTicketsForm from "./SarahAndCamBuyTicketsForm";
import BigSistersBuyTicketsForm from "./BigSistersBuyTicketsForm";
import YoungProfessionalBuyTicketsForm from "./YoungProfessionalBuyTicketsForm";
import SponsorshipBuyTicketsForm from "./SponsorshipBuyTicketsForm";

console.log(process.env.REACT_APP_STRIPE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeContainer = ({
  updateIsLoading,
  updateHasDonated,
  amount,
  updateAmount,
  formType,
  payFees,
  togglePayFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateRegistrationURL,
  selectedTicketQuantityOption,
  ticketQuantityOptions
}) => {

  // Render different types of DonationForms based on the 'formType' prop
  const renderDonationForm = () => {
    switch (formType) {
      case "VirtualTerminal":
        return (
          <VirtualTerminalForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
          />
        );
      case "Cobs":
        return (
          <CobsCampaignForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
          />
        );
      case "Book":
        return (
          <BookCampaignForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
          />
        );
      case "Campaign":
        return (
          <CampaignForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
          />
        );
      case "BuyTickets":
        return (
          <BuyTicketsForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
            ticketOptions={ticketOptions}
            updateTicketOption={updateTicketOption}
            selectedTicketOption={selectedTicketOption}
            selectedTicketQuantityOption={selectedTicketQuantityOption}
            updateRegistrationURL={updateRegistrationURL}
            ticketQuantityOptions={ticketQuantityOptions}
          />
        );
        case "YoungProfessionalsBuyTickets":
          return (
            <YoungProfessionalBuyTicketsForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
            ticketOptions={ticketOptions}
            updateTicketOption={updateTicketOption}
            selectedTicketOption={selectedTicketOption}
            selectedTicketQuantityOption={selectedTicketQuantityOption}
            updateRegistrationURL={updateRegistrationURL}
            ticketQuantityOptions={ticketQuantityOptions}
            />
          );
          case "SponsorshipBuyTickets":
            return (
              <SponsorshipBuyTicketsForm
              updateIsLoading={updateIsLoading}
              updateHasDonated={updateHasDonated}
              amount={amount}
              updateAmount={updateAmount}
              payFees={payFees}
              togglePayFees={togglePayFees}
              updateFirstName={updateFirstName}
              ticketOptions={ticketOptions}
              updateTicketOption={updateTicketOption}
              selectedTicketOption={selectedTicketOption}
              selectedTicketQuantityOption={selectedTicketQuantityOption}
              updateRegistrationURL={updateRegistrationURL}
              ticketQuantityOptions={ticketQuantityOptions}
              />
            );
        case "SarahAndCamBuyTickets":
          return (
            <SarahAndCamBuyTicketsForm
              updateIsLoading={updateIsLoading}
              updateHasDonated={updateHasDonated}
              amount={amount}
              updateAmount={updateAmount}
              payFees={payFees}
              togglePayFees={togglePayFees}
              updateFirstName={updateFirstName}
              ticketOptions={ticketOptions}
              updateTicketOption={updateTicketOption}
              selectedTicketOption={selectedTicketOption}
              selectedTicketQuantityOption={selectedTicketQuantityOption}
              updateRegistrationURL={updateRegistrationURL}
              ticketQuantityOptions={ticketQuantityOptions}
            />
          );
          case "BigSisters":
            return (
              <BigSistersBuyTicketsForm
                updateIsLoading={updateIsLoading}
                updateHasDonated={updateHasDonated}
                amount={amount}
                updateAmount={updateAmount}
                payFees={payFees}
                togglePayFees={togglePayFees}
                updateFirstName={updateFirstName}
                ticketOptions={ticketOptions}
                updateTicketOption={updateTicketOption}
                selectedTicketOption={selectedTicketOption}
                selectedTicketQuantityOption={selectedTicketQuantityOption}
                updateRegistrationURL={updateRegistrationURL}
                ticketQuantityOptions={ticketQuantityOptions}
              />
            );
      default:
        return (
          <BuyTicketsForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
            ticketOptions={ticketOptions}
            updateTicketOption={updateTicketOption}
            selectedTicketOption={selectedTicketOption}
            selectedTicketQuantityOption={selectedTicketQuantityOption}
            updateRegistrationURL={updateRegistrationURL}
            ticketQuantityOptions={ticketQuantityOptions}
          />
        );
    }
  };

  return <Elements stripe={stripePromise}>{renderDonationForm()}</Elements>;
};

export default StripeContainer;
