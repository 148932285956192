import { calculateTotalAmount, extractFirstAndLastName } from './paymentUtils';


const createOtherIntentPayment = async (stripe, updateFirstName, amount, payFees, e, toast, updateHasDonated, 
  formData, ticketQty, selectedTicketOption, updateRegistrationURL) => {
    const paymentMethod = e.methodName;
    const paymentCard = e.paymentMethod.card.brand;

    const { firstName, lastName } = extractFirstAndLastName(e.payerName);
    updateFirstName(firstName);
    const originURL = window.location.href;

    const { error: backendError, clientSecret, attendee_url } = await fetch(
      `${process.env.REACT_APP_API_URL}/create-payment-intent/tickets`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodType: "card",
          currency: "cad",
          amount: parseInt(calculateTotalAmount(amount, payFees, formData) * 100),
          originalAmount: amount * 100,
          payFees: payFees,
          email: e.payerEmail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: e.payerPhone,
          addressLine1: e.paymentMethod.billing_details.address.line1,
          addressLine2: e.paymentMethod.billing_details.address.line2,
          city: e.paymentMethod.billing_details.address.city,
          province: e.paymentMethod.billing_details.address.state,
          zip: e.paymentMethod.billing_details.address.postal_code,
          originURL: originURL,
          paymentMethod: paymentMethod,
          paymentMethodId: e.paymentMethod.id,
          paymentCard: paymentCard,
          ticketQty: ticketQty,
          ticketOption: selectedTicketOption.value,
          ticketCost: selectedTicketOption.cost,
          attendEvent: true,
          dietary: formData.dietary,
          moreNeeds: formData.moreNeeds,
          saveInfo: formData.saveInfo,
          organizationName: formData.organizationName,
          voucher: formData.voucher,
          filename: formData.filename
        }),
      }
    ).then((r) => r.json());

    if (backendError) {
      toast.error(backendError.message);
      return;
    }

    if (selectedTicketOption.value !== "free-ticket") {
      const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: e.paymentMethod.id,
        },
        { handleActions: false }
      );

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      toast.error(stripeError.message);

      // Complete the payment request to dismiss the Apple Pay dialog on error
      e.complete("fail");
      return;
    }
    }



    // Complete the payment request to dismiss the Apple Pay dialog
    e.complete("success");
    updateRegistrationURL(attendee_url);
    updateHasDonated(true);
};

export default createOtherIntentPayment;
