import React from "react";
import CustomChoiceDropdown from "./CustomChoiceDropdown";
import { formatAsCurrency } from "../services/formUtils";
import QuantitySelector from "./QuantitySelector";

class SponsorshipTicketForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      options,
      ticketQty,
      selectedTicketOption,
      updateTicketQuantity,
      getAmount,
      updateTicketOption,
      selectedTicketQuantityOption,
      ticketQuantityOptions,
      handleInputChange,
      formData
    } = this.props; // Destructure props

    return (
      <>
        <QuantitySelector
          option={selectedTicketQuantityOption}
          handleChoiceUpdate={updateTicketOption}
          label={"Number of Tickets?"}
          identifier="selectedTicketQuantityOption"
          choiceOptions={ticketQuantityOptions}
          defaultValue={selectedTicketQuantityOption}
          isRequired={true}
        />
        <CustomChoiceDropdown
          value={selectedTicketOption}
          handleChoiceUpdate={updateTicketOption}
          label={"Pick a Ticket Type"}
          identifier="selectedTicketOption"
          choiceOptions={options}
          defaultValue={selectedTicketOption}
          isRequired={true}
        />

        {(selectedTicketOption.value.includes("corporate") || selectedTicketOption.value.includes("table")|| selectedTicketOption.value.includes("ambassador")) && <>
        <div className="col-md-12">
            <div className="d-flex flex-wrap align-items-center" style={{marginLeft: "13px"}}>
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="ticketInfo"
              >
                &nbsp;&nbsp;10 tickets per table
              </label>
            </div>
        </div>
        </>}


      </>
    );
  }
}

export default SponsorshipTicketForm;
