import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import AddressForm from "./SponsorshipAddressForm";
import ContactForm from "./ContactForm";
import BillingForm from "./BillingForm";
import { useLocation } from "react-router-dom";
import createIntentPayment from "../services/tickets_no_payment";
import {
  handleCountryChange as handleCountryChangeUtil,
  handleBillingCountryChange as handleBillingCountryChangeUtil,
} from "../services/formUtils";
import TicketForm from "./SponsorshipTicketForm";
import TicketNameForm from "./SponsorshipTicketNameForm";
import DietaryForm from "./DietaryForm";
import MoreNeedsForm from "./MoreNeedsForm";
import axios from "axios";

const SponsorshipBuyTicketsForm = ({
  updateIsLoading,
  updateHasDonated,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateRegistrationURL,
  selectedTicketQuantityOption,
  ticketQuantityOptions,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const [creditCard, setCreditCard] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200); // Detect if initially on mobile

  const [titlePrefix, setTitlePrefix] = useState('');

  const getAmount = (selectedTicketQuantityOption, selectedTicketOption) => {
    return selectedTicketQuantityOption.value * selectedTicketOption.cost;
  };


  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  useEffect(() => {
    const isTest = window.location.hostname.includes('test.');

    if (isTest) {
      setTitlePrefix(<> [Staff Only, Test]</> );
    } else {
      setTitlePrefix(<> [Staff Only]</> );
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChoiceUpdate = (event, label) => {
    const { checked } = event.target;
    setFormData({
      ...formData,
      [label]: checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    await createIntentPayment(
      stripe,
      cardElement,
      updateFirstName,
      getAmount(selectedTicketQuantityOption, selectedTicketOption),
      payFees,
      formData,
      updateHasDonated,
      toast,
      setFormData,
      updateIsLoading,
      selectedTicketQuantityOption.value,
      selectedTicketOption,
      updateRegistrationURL
    );
  };

  const handleCountryChange = (selectedOption) => {
    handleCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingCountryChange = (selectedOption) => {
    handleBillingCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };




  const handleFileUpload = async (file, filename) => {
    updateIsLoading(true);
    const requestBody = {
      filename: filename,
    };

    try {
      // Call the API endpoint to get the file upload details
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/brenda/auction-items/file`,
        requestBody
      );

      const { url, fields } = response.data;
      const tempUrl = url;

      // Prepare form data with the file to be uploaded
      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Perform the file upload using the obtained URL and fields
      await axios.post(tempUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

        toast.success("File upload successfully!");
    } catch (error) {
      // Handle error scenarios
      console.error("Error uploading file:", error);

      toast.error("File upload failed!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    updateIsLoading(false);
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const uniqueCode = Date.now().toString().substring(6); // Using a substring of current timestamp

    // Replace spaces in filename with underscores
    let filename = file.name.replace(/\s/g, '_');

    // Remove accented characters
    filename = filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const filenameWithCode = `${uniqueCode}_${filename}`;

    await handleFileUpload(file, filenameWithCode);

    handleInputChange({ target: { name: e.target.name, value: file } });

    setFormData({
      ...formData,
      "filename": filenameWithCode
    });


  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h4 style={{ color: "#2dccd3" }}>
            Big Sisters’ Soirée <i>presented by Scotiabank</i>{titlePrefix}
          </h4>
          <p style={{ color: "#2dccd3", fontWeight: "bold" }}>
          Big Sisters' Staff Form. For internal use only.
          </p>
          <div className="row  text-light">
                <TicketForm
                  handleInputChange={handleInputChange}
                  formData={formData}
                  options={ticketOptions}
                  selectedTicketOption={selectedTicketOption}
                  getAmount={getAmount}
                  updateTicketOption={updateTicketOption}
                  selectedTicketQuantityOption={selectedTicketQuantityOption}
                  ticketQuantityOptions={ticketQuantityOptions}
                />

                

{selectedTicketOption.label.includes("Table") && <>
                
                <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="logoUpload">Upload Organization Logo</label>
                <input
                  type="file"
                  className="form-control"
                  id="logoUpload"
                  name="logoUpload"
                  onChange={handleFileChange}
                />
              </div>
            </div>
                </> }



                <DietaryForm handleInputChange={handleInputChange} 
                  formData={formData}
                  />


<MoreNeedsForm handleInputChange={handleInputChange} 
                  formData={formData}
                  />

                <TicketNameForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                  creditCardSectionTitle={
                    applePay || googlePay
                      ? "Or Credit Card Payment"
                      : "Credit Card Payment"
                  }
                  setCreditCard={setCreditCard}
                />

                <ContactForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />


                <AddressForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />


                <button className="btn btn-theme effect btn-md" type="submit">
                  Send Tickets
                </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SponsorshipBuyTicketsForm;