import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BlueSection from "./BlueSection";
import { ReactComponent as BackgroundSVG } from "../assets/img/background.svg";
import React, { Component } from "react";
import axios from "axios";

class AuthorizationForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        url_query: this.props.url_query,
        verificationCode: "",
        isLoading: false,
        error: null,
        showModal: false,
        realVerificationCode: "",
        initialLoad: false,
        submitted: false
      };
    }
  
    componentDidMount() {
    }
  
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    handleSubmit = async (e) => {
      e.preventDefault();
  
      this.setState({ isLoading: true, error: null, submitted: true });
      
      try {
        // Make API call to send verification code
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/verify-code`, {
          url_query: this.props.url_query
        });
  
        const code = response.data["Verification Code"];
        console.log(code);
  
        // Show modal to enter verification code
        this.setState({ showModal: true, realVerificationCode: code });
      } catch (error) {
        this.setState({ error: error.message });
      } finally {
        this.setState({ isLoading: false });
      }
    };
  
    handleModalSubmit = async (e) => {
        console.log("SCREAMMM!!!");
        console.log(this.state.verificationCode);
        console.log(this.state.realVerificationCode);
        e.preventDefault();
      try {
        // If verification is successful, set isAuthenticated to true
        if (this.state.verificationCode.toString() === this.state.realVerificationCode.toString()) {
            console.log("Verified!");
            this.props.authenticate();
        } else {
            console.log("Verification code is incorrect");
          throw new Error("Verification code is incorrect");
        }
      } catch (error) {
        console.log(error);
        this.setState({ error: error.message });
      }
    };


render() {
    return (
      <>
        <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className="fixed-bg"
            id="bg-wrap"
          >
            <BackgroundSVG />
          </div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 info"></div>
              <div className="col-lg-6 info text-light donation-form">
              <div className="thank-you-page">
                    <div className="form-box">
                    <form>
                        <h3 style={{color: '#2dccd3'}}>Registration</h3>
                        <p style={{color:"#232323"}}>
                        For security purposes, we need to authorize you.
                        </p>

                        {this.state.showModal ? <>
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="verificationCode">
                                    Enter the verification code we just texted you
                                    <span className="required">*</span>
                                    </label>
                                    <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="verificationCode"
                                        placeholder="Verification Code"
                                        onChange={e => {this.setState({verificationCode: e.target.value})}}
                                        required
                                    />
                                    </div>
                                    {this.state.error && <p style={{color: "red"}} className="error-message">{this.state.error}</p>}
                                </div>
                                </div>
                                <button className="btn btn-theme effect btn-md" type="submit" onClick={this.handleModalSubmit}>
                                Verify Now
                                </button>
                        </> : 
                                    <button className="btn btn-theme effect btn-md donate-again" type="submit" 
                                    onClick={this.handleSubmit}>
                                    Begin Authorization Process
                                    </button>
                        }




                    </form>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default AuthorizationForm;