import React from "react";
import CustomChoiceDropdown from "./CustomChoiceDropdown";
import { formatAsCurrency } from "../services/formUtils";
import QuantitySelector from "./QuantitySelector";

class YoungProfessionalTicketForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      options,
      ticketQty,
      selectedTicketOption,
      updateTicketQuantity,
      getAmount,
      updateTicketOption,
      selectedTicketQuantityOption,
      ticketQuantityOptions,
      handleInputChange,
      formData
    } = this.props; // Destructure props

    return (
      <>
        <QuantitySelector
          option={selectedTicketQuantityOption}
          handleChoiceUpdate={updateTicketOption}
          label={"Number of Tickets?"}
          identifier="selectedTicketQuantityOption"
          choiceOptions={ticketQuantityOptions}
          defaultValue={selectedTicketQuantityOption}
          isRequired={true}
        />
        <CustomChoiceDropdown
          value={selectedTicketOption}
          handleChoiceUpdate={updateTicketOption}
          label={"Pick a Ticket Type"}
          identifier="selectedTicketOption"
          choiceOptions={options}
          defaultValue={selectedTicketOption}
          isRequired={true}
        />

{(selectedTicketOption.value === "corporate" || selectedTicketOption.value === "table") && <>
        <div className="col-md-12">
            <div className="d-flex flex-wrap align-items-center" style={{marginLeft: "13px"}}>
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="ticketInfo"
              >
                &nbsp;&nbsp;10 tickets per table
              </label>
            </div>
        </div>
        </>}

        <div className="col-md-12">
            <div className="d-flex flex-wrap align-items-center" style={{marginLeft: "13px"}}>
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="coverFeeOption"
              >
                &nbsp;&nbsp;Your ticket subtotal is:&nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {formatAsCurrency(getAmount(selectedTicketQuantityOption, selectedTicketOption))}
                </span>
                &nbsp;
              </label>
            </div>
        </div>

      </>
    );
  }
}

export default YoungProfessionalTicketForm;
