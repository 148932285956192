import { calculateTotalAmount } from './paymentUtils';
import {
  initialState
} from "../components/constants";

const generateQrCodeData = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomValue = '';
  for (let i = 0; i < 10; i++) {
    randomValue += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomValue;
};

const createIntentPayment = async (stripe, cardElement, updateFirstName, amount, payFees, 
  formData, updateHasDonated, toast, setFormData, updateIsLoading, ticketQty, selectedTicketOption, 
  updateRegistrationURL) => {
    const originURL = window.location.href;

    try {
      let body = { ...formData };
      body["paymentMethodId"] = `PAY_BIGSIS_${formData.organizationName}_${formData.email}`;
      body["province"] = body.selectedProvince.value;
      body["country"] = body.selectedCountry.value;
      body["selectedWording"] = body.selectedWording.value;
      body["provinceOptions"] = "";
      body["amount"] = calculateTotalAmount(amount, payFees, formData);
      body["billingProvince"] = body.billingSelectedProvince.value;
      body["billingCountry"] = body.billingSelectedCountry.value;
      body["originalAmount"] = amount;
      body["payFees"] = payFees;
      body["originURL"] = originURL;
      body["ticketQty"] = ticketQty;
      body["ticketOption"] = selectedTicketOption.value;
      body["ticketCost"] = selectedTicketOption.cost;
      body["sponsorship"] = true;

      body["attendEvent"] = 
      formData.firstName && formData.firstName.trim() !== '' &&
      formData.lastName && formData.lastName.trim() !== '' &&
      formData.addressLine1 && formData.addressLine1.trim() !== '' &&
      formData.city && formData.city.trim() !== '' &&
      formData.zip && formData.zip.trim() !== '';

      body["purchase_id"] = `PUR_BIGSIS_${formData.organizationName}_${formData.email}`
      body["barcode"] = generateQrCodeData();

      body["filename"] = formData.filename;

      updateFirstName(body["firstName"]);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/attendees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        toast.error("There was an error with the form");
      } else {
        const url_query = response.text;

        updateRegistrationURL(url_query);

        setFormData(initialState);

        toast.success("Tickets Generated!");
      }
      updateIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("There was an error with the form");
      updateIsLoading(false);
    }
};

export default createIntentPayment;
