import React, { Component } from "react";
import "../assets/css/ThankYouPage.css";

class TicketThankYouPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDonateAgain = (e) => {
    e.preventDefault();

    this.props.updateHasDonated(false);
  };

  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = `/register/${this.props.registrationURL}`; // Redirect to Big Sisters website
  };

  render() {
    const { selectedTicketOption } = this.props;

    return (
      <div className="thank-you-page">
        <div className="form-box">
          <form>
            <h3 style={{ color: "#2dccd3" }}>
              {this.props.firstName ? (
                <>Thank You, {this.props.firstName}!</>
              ) : (
                <>Thank You!</>
              )}
            </h3>
      
              <p style={{ color: "#232323", fontWeight: "bold" }}>
                Check your inbox for your ticket!<br/>Tax receipts will be provided after the event.
              </p>


            {(this.props.selectedTicketQuantityOption.value > 1 || selectedTicketOption.value.includes("corporate") || selectedTicketOption.value.includes("ambassador")
            || selectedTicketOption.value.includes("table"))  && (
              <p style={{ color: "#232323" }}>
                Click{" "}
                <a
                  href={`/register/${this.props.registrationURL}`}
                  style={{ color: "#fcb61c" }}
                >
                  here
                </a>{" "}
                to confirm your registration, and register your guest(s).
              </p>
            )}

            <p style={{ color: "#232323" }}>
              Supporters like you make it possible for Big Sisters to bring
              mentorship programs to girls and gender-diverse youth in our community. Thank you
              for helping to ignite the potential of youth across the Lower
              Mainland.
            </p>

            {(this.props.selectedTicketQuantityOption.value > 1 || selectedTicketOption.value === "corporate" 
            || selectedTicketOption.value === "table") && (
              <>
                <button
                  className="btn btn-theme effect btn-md donate-again"
                  type="submit"
                  onClick={this.handleContinue}
                >
                  Register Now
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default TicketThankYouPage;