import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  stateOptions,
  provinceOptions,
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import ContactForm from "./ContactForm";
import AmountForm from "./AmountForm";
import { useLocation } from "react-router-dom";
import ClassificationForm from './ClassificationForm'
import ReceiptOptions from "./ReceiptOptions";
import TributeForm from "./TributeForm";
import NotifyContactForm from "./NotifyContactForm";
import SingleOption from "./SingleOption";

const VirtualTerminalForm = ({ updateIsLoading, updateHasDonated, amount, updateAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");


  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const getWelcomeMessage = () => {
    let message = "Virtual Terminal";
    const currentURL = window.location.href.toLowerCase(); // Convert URL to lowercase
  
    if (currentURL.includes("/marley")) {
      message = "Marley's Virtual Terminal";
    } else if (currentURL.includes("/nathania")) {
      message = "Nathania's Virtual Terminal";
    } else if (currentURL.includes("/marni")) {
      message = "Marni's Virtual Terminal";
    } else if (currentURL.includes("/cindy")) {
      message = "Cindy's Virtual Terminal";
    } else if (currentURL.includes("/chantelle")) {
      message = "Chantelle's Virtual Terminal";
    }
  
    return message;
  };
  


  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 1000,
      },
    });
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
  }, [stripe, elements]);


  const toggleMonthly = (e) => {
    setFormData({
      ...formData,
      monthly: !formData.monthly,
    });
  };

  const toggleValue = (e, label) => {
    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === 'amount') {
      updateAmount(event.target.value);
    }
    else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      toast.error(
        "Payment failed. Please try again or use a different credit card."
      );
      updateIsLoading(false);
      return;
    }

    try {
      let body = { ...formData };
      body["paymentMethodId"] = paymentMethod.id;
      body["province"] = body.selectedProvince.value;
      body["country"] = body.selectedCountry.value;
      body["selectedWording"] = body.selectedWording.value;
      body["provinceOptions"] = "";
      body["amount"] = amount;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        toast.error("Payment Error! Please try again later.");
      } else {
        const data = await response.json();
        console.log(data); // Success message from backend
        const message = data["message"];
        toast.success(`${message} Form will refresh in a few seconds.`);

        updateHasDonated(true);

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      updateIsLoading(false);

    } catch (error) {
      console.error(error);
      toast.error("Payment failed. Please try again.");
      updateIsLoading(false);
    }
  };

  const handleCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };


  const handleUpdateReceipt = (selectedReceipt) => {
    setFormData({ ...formData, receipt:selectedReceipt });
  }

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h3 style={{ color: "#2dccd3" }}>
            {getWelcomeMessage()}
          </h3>
          <div className="row  text-light">
            <AmountForm
              handleInputChange={handleInputChange}
              formData={formData}
              toggleMonthly={toggleMonthly}
              isMonthly={isMonthly}
            />

            <ClassificationForm
              handleInputChange={handleInputChange}
              formData={formData}
            />

            <ReceiptOptions selectedType={formData.receipt} handleTypeClick={handleUpdateReceipt} />
            
            <ContactForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              creditCardSectionTitle="Credit Card Information"
            />

<div className="col-md-12">
              <div className="form-group">
                <label htmlFor="tribute">Is it a</label>
                <SingleOption
                  value={formData.tribute}
                  toggleValue={(e) => toggleValue(e, "tribute")}
                  label="Tribute?"
                />
              </div>
            </div>

            {formData.tribute && (
              <TributeForm
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                toggleValue={toggleValue}
              />
            )}

            {formData.notify && (
              <NotifyContactForm
                formData={formData}
                handleInputChange={handleInputChange}
                handleProvinceChange={handleProvinceChange}
                toggleValue={toggleValue}
                handleCountryChange={handleCountryChange}
              />
            )}

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="cardNumber">
                  Donate by Credit Card <span className="required">*</span>
                </label>
                <div className="input-group">
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>
            <button className="btn btn-theme effect btn-md" type="submit">
              Start Payment Process
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VirtualTerminalForm;
