import { calculateTotalAmount } from './paymentUtils';
import {
  initialState
} from "../components/constants";

const createIntentPayment = async (stripe, cardElement, updateFirstName, amount, payFees, formData, updateHasDonated, toast, setFormData, updateIsLoading) => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      toast.error(
        "Payment failed. Please try again or use a different credit card."
      );
      updateIsLoading(false);
      return;
    }

    const originURL = window.location.href;

    try {
      let body = { ...formData };
      body["paymentMethodId"] = paymentMethod.id;
      body["province"] = body.selectedProvince.value;
      body["country"] = body.selectedCountry.value;
      body["selectedWording"] = body.selectedWording.value;
      body["provinceOptions"] = "";
      body["amount"] = calculateTotalAmount(amount, payFees, formData);
      body["billingProvince"] = body.billingSelectedProvince.value;
      body["billingCountry"] = body.billingSelectedCountry.value;
      body["originalAmount"] = amount;
      body["payFees"] = payFees;
      body["originURL"] = originURL;

      updateFirstName(body["firstName"]);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payments/campaigns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        toast.error("Payment Error! Please try again later.");
      } else {
        const data = await response.json();
        console.log(data); // Success message from backend

        setFormData(initialState);

        updateHasDonated(true);
      }
      updateIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Payment failed. Please try again.");
      updateIsLoading(false);
    }
};

export default createIntentPayment;
