import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplePayButton from "./ApplePayButton";
import GooglePayButton from "./GooglePayButton";
import {
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState
} from "./constants";
import AddressForm from "./AddressForm";
import ContactForm from "./ContactForm";
import BillingForm from "./BillingForm";
import { useLocation } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import createIntentPayment from "../services/tickets_payment_intent_exclusive";
import createOtherIntentPayment from "../services/tickets_other_pay_payment_intent_exclusive";
import { calculateTotalAmount } from "../services/paymentUtils";
import {
  handleCountryChange as handleCountryChangeUtil,
  handleBillingCountryChange as handleBillingCountryChangeUtil,
  tooltip,
  saveInfoTooltip,
  formatAsCurrency,
} from "../services/formUtils";
import TicketForm from "./YoungProfessionalTicketForm";
import TicketNameForm from "./TicketNameForm";
import CreditCardPayButton from "./CreditCardPayButton";
import DietaryForm from "./DietaryForm";
import MoreNeedsForm from "./MoreNeedsForm";

const YoungProfessionalBuyTicketsForm = ({
  updateIsLoading,
  updateHasDonated,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateRegistrationURL,
  selectedTicketQuantityOption,
  ticketQuantityOptions,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const [creditCard, setCreditCard] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200); // Detect if initially on mobile

  const [titlePrefix, setTitlePrefix] = useState('');
  const getAmount = (selectedTicketQuantityOption, selectedTicketOption) => {
    return selectedTicketQuantityOption.value * selectedTicketOption.cost;
  };

  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      requestPayerPhone: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 30,
      },
    });

    const applePaySupported =
      window.ApplePaySession && window.ApplePaySession.canMakePayments();
    setApplePay(applePaySupported);

    if (applePaySupported) {
      setPaymentRequest(pr);
    }

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        //setApplePay(result.applePay);
        setGooglePay(result.googlePay);
      }
    });

    pr.on("paymentmethod", async (e) => {
      await createOtherIntentPayment(
        stripe,
        updateFirstName,
        getAmount(selectedTicketQuantityOption, selectedTicketOption),
        payFees,
        e,
        toast,
        updateHasDonated,
        formData,
        selectedTicketQuantityOption.value,
        selectedTicketOption,
        updateRegistrationURL,
        markVoucherAsUsed
      );
    });
  }, [
    stripe,
    elements,
    payFees,
    updateFirstName,
    selectedTicketQuantityOption,
    selectedTicketOption,
    updateRegistrationURL,
    formData,
  ]);

  const updatePaymentRequestInstance = async (event) => {
    paymentRequest.update({
      country: "CA",
      currency: "cad",
      total: {
        label: "Purchase Tickets to Big Sisters' Event",
        amount: parseInt(
          calculateTotalAmount(
            getAmount(selectedTicketQuantityOption, selectedTicketOption),
            payFees,
            formData
          ) * 100
        ),
      },
    });
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  const fetchVouchers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/vouchers`);
      const data = await response.json();

      setVouchers(data);
    } catch (error) {
      console.error("Error fetching raffle:", error);
    }
  }

  const markVoucherAsUsed = async (voucherCode) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/vouchers?code=${voucherCode}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const result = await response.json();

        if (result.success) {
            this.fetchVouchers();  // Refresh the voucher list after marking one as used
        } else {
            this.setState({ error: "Unable to mark voucher as used" });
        }
    } catch (error) {
        console.error("Error marking voucher as used:", error);
        this.setState({ error: "An error occurred while updating the voucher." });
    }
};


  useEffect(() => {
    const isTest = window.location.hostname.includes('test.');

    if (isTest) {
      setTitlePrefix(<> [Test]</> );
    }

    fetchVouchers();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChoiceUpdate = (event, label) => {
    const { checked } = event.target;
    setFormData({
      ...formData,
      [label]: checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    await createIntentPayment(
      stripe,
      cardElement,
      updateFirstName,
      getAmount(selectedTicketQuantityOption, selectedTicketOption),
      payFees,
      formData,
      updateHasDonated,
      toast,
      setFormData,
      updateIsLoading,
      selectedTicketQuantityOption.value,
      selectedTicketOption,
      updateRegistrationURL,
      markVoucherAsUsed
    );
  };

  const handleCountryChange = (selectedOption) => {
    handleCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingCountryChange = (selectedOption) => {
    handleBillingCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  const handleRealButtonClick = () => {
    updatePaymentRequestInstance();
    paymentRequest.show();
  };

  const renderGooglePaymentMethodLabel = () => {
    if (applePay) {
      if (!isMobile) {
        return (
          <label htmlFor="GooglePay">
            <span style={{ color: "white" }}>Google Pay</span>
          </label>
        );
      }
    } else {
      return <label htmlFor="GooglePay">Payment Method:</label>;
    }

    return <></>;
  };

  const renderCreditCardPaymentMethodLabel = () => {
    if (applePay || googlePay) {
      if (!isMobile) {
        return (
          <label htmlFor="CreditCardPay">
            <span style={{ color: "white" }}>Credit Card</span>
          </label>
        );
      }
    } else {
      return <label htmlFor="CreditCardPay">Payment Method:</label>;
    }

    return <></>;
  };

  const checkVoucher = () => {
    if (formData.voucher) {
      const lowerCaseVoucher = formData.voucher.toLowerCase();
  
      // Filter out vouchers that have already been used
      const availableVouchers = vouchers.filter(voucher => !voucher.alreadyUsed);
  
      const lowerCaseListValues = availableVouchers.map(value => value.code.toLowerCase());
  
      return lowerCaseListValues.includes(lowerCaseVoucher);
    }
  
    return false;
  }
  

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
        <h4 style={{ color: "#2dccd3" }}>
            Big Sisters’ Soirée <i>presented by Scotiabank</i>{titlePrefix}
          </h4>
          <p style={{ color: "black" }}>
          Exclusive rate tickets for 2024<br/>
          $300 for a Young Professional ticket
          <br/>
          Ticket Sale Closing Date - September 20th, 2024.
          </p>
          <div className="row  text-light">
            {!creditCard ? (
              <>


                <div className="col-md-12">
                  <div className="d-flex flex-wrap align-items-center">
                    <label
                      style={{ display: "flex", alignItems: "center" }}
                      className="coverFeeOption"
                    >
                      <input
                        type="checkbox"
                        onChange={togglePayFees}
                        checked={payFees}
                        style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                      />
                      {isMobile ? (
                        <>&nbsp;&nbsp;Cover transaction fee&nbsp;</>
                      ) : (
                        <>
                          &nbsp;&nbsp;I will generously cover the&nbsp;
                          <span style={{ fontWeight: "bold" }}>
                            transaction fees
                          </span>
                          .&nbsp;
                        </>
                      )}

                      <OverlayTrigger placement="right" overlay={tooltip}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ color: "#2dccd3" }}
                        />
                      </OverlayTrigger>
                    </label>
                  </div>
                  {payFees &&
                    getAmount(
                      selectedTicketQuantityOption,
                      selectedTicketOption
                    ) > 0 && (
                      <>
                        <p style={{ color: "black" }}>
                          Your payment will come to{" "}
                          {formatAsCurrency(
                            calculateTotalAmount(
                              getAmount(
                                selectedTicketQuantityOption,
                                selectedTicketOption
                              ),
                              payFees,
                              formData
                            )
                          )}
                          .
                        </p>
                      </>
                    )}
                </div>

                <div className="col-md-12">
                  <div className="d-flex flex-wrap align-items-center">
                    <label
                      style={{ display: "flex", alignItems: "center" }}
                      className="coverFeeOption"
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => handleChoiceUpdate(e, "saveInfo")}
                        checked={formData.saveInfo}
                        style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                      />
                      {isMobile ? (
                        <>&nbsp;&nbsp;Save my payment info?&nbsp;</>
                      ) : (
                        <>
                          &nbsp;&nbsp;Securely save my payment information?
                          &nbsp;
                        </>
                      )}

                      <OverlayTrigger
                        placement="right"
                        overlay={saveInfoTooltip}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ color: "#2dccd3" }}
                        />
                      </OverlayTrigger>
                    </label>
                  </div>
                </div>


                <div
                  className="col-md-12"
                  style={{
                    textAlign: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      color: "#54585a",
                      backgroundColor: "#efefef",
                    }}
                  >
                    <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
                  </div>
                </div>

                <DietaryForm handleInputChange={handleInputChange} 
                  formData={formData}
                  />



<MoreNeedsForm handleInputChange={handleInputChange} 
                  formData={formData}
                  />
<div
                  className="col-md-12"
                  style={{
                    textAlign: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      color: "#54585a",
                      backgroundColor: "#efefef",
                    }}
                  >
                    <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
                  </div>
                </div>



                <div className="col-lg-12">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="voucher">Enter your Voucher Code:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="voucher"
                        placeholder="Voucher Code"
                        value={formData.voucher}
                        onChange={handleInputChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <p style={{color: "#2dccd3", fontWeight: "bold"}}> {checkVoucher() ? "You may now purchase your exclusive rate tickets": "Individual voucher codes can only be applied to one single ticket purchase."}</p>
                </div>
                {checkVoucher() && <>
                
                  {applePay && (
                  <div className="col-xl-6">
                    <div className="form-group">
                      <label htmlFor="ApplePay">Payment Method:</label>
                      <div className="input-group">
                        <ApplePayButton handleClick={handleRealButtonClick} />
                      </div>
                    </div>
                  </div>
                )}

                {googlePay && (
                  <div className="col-xl-6">
                    <div className="form-group">
                      {renderGooglePaymentMethodLabel()}

                      <div className="input-group">
                        <GooglePayButton handleClick={handleRealButtonClick} />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-xl-6">
                  <div className="form-group">
                    {renderCreditCardPaymentMethodLabel()}

                    <div className="input-group">
                      <CreditCardPayButton
                        handleClick={(e) => {
                          e.preventDefault();
                          setCreditCard(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                </>}


                <div
                  className="col-md-12"
                  style={{
                    textAlign: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      color: "#54585a",
                      backgroundColor: "#efefef",
                    }}
                  >
                    <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
                  </div>
                </div>

                <p style={{ color: "black" }}>
                  <b>Date:</b> Saturday, October 5, 2024
                </p>
                <p style={{ color: "black" }}>
                  <b>Location:</b> The Westin Bayshore - 1601 Bayshore Drive, Vancouver, BC V6G 2V4
                </p>

                <p style={{ color: "#2dccd3", fontWeight: "bold" }}>
                  Reserve your ticket today!
                </p>
              </>
            ) : (
              <>
                <TicketNameForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                  creditCardSectionTitle={
                    applePay || googlePay
                      ? "Or Credit Card Payment"
                      : "Credit Card Payment"
                  }
                  setCreditCard={setCreditCard}
                />

                <ContactForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="cardNumber">
                      Pay by Credit Card <span className="required">*</span>
                    </label>
                    <div className="input-group">
                      <CardElement
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </div>
                  </div>
                </div>

                <AddressForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />

                <BillingForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />

                <button className="btn btn-theme effect btn-md" type="submit">
                  Buy Tickets Now
                </button>

                <div className="question">
                  <div className="icon">
                    <i className="fas fa-phone"></i>
                  </div>

                  <div className="info">
                    <h5 style={{ color: "rgb(45, 204, 211)" }}>
                      Have any questions about your Yoga 2024 ticket(s)
                      purchase?
                    </h5>
                    <span style={{ color: "#232323" }}>
                      Call Now: 604.873.4525 ext 108
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default YoungProfessionalBuyTicketsForm;