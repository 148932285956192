import React from "react";
import Select from "react-select"; // Import Select from react-select
import {
  DROPDOWN_STYLE,
  countryOptions,
  provinceOptions,
  stateOptions,
} from "./constants"; // Import DROPDOWN_STYLE
import "../assets/css/AttendeeForm.css";

class AttendeeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        attendee_id: props.attendee.attendee_id,
        firstName: props.attendee.attendee_firstname,
        lastName: props.attendee.attendee_lastname,
        email: props.attendee.email,
        phoneNumber: props.attendee.phone_number,
        addressLine1: props.attendee.address_line_1,
        addressLine2: props.attendee.address_line_2,
        city: props.attendee.city,
        provinceLabel: "Province",
        provinceOptions: provinceOptions,
        zip: props.attendee.postal_code,
        zipLabel: "Postal Code",
        dietary: props.attendee.dietary,
        organizationName: props.attendee.organizationName,
        ticketOption: props.attendee.ticketOption,
        moreNeeds: props.attendee.moreNeeds,
      },
      errorEmail: "",
      errorPhoneNumber: "",
    };

    // Find the provinceOption whose value matches props.attendee.phone_number
    const selectedProvince = provinceOptions.find(
      (option) => option.value === props.attendee.province
    );
    // Set selectedProvince to the found option or the first option if no match is found
    this.state.formData.selectedProvince =
      selectedProvince || provinceOptions[0];

    // Find the countryOption whose value matches props.attendee.phone_number
    const selectedCountry = countryOptions.find(
      (option) => option.value === props.attendee.country
    );
    // Set selectedCountry to the found option or the first option if no match is found
    this.state.formData.selectedCountry = selectedCountry || countryOptions[0];
  }

  handleInputChange = (event) => {
    const { id, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [id]: value,
      },
    }));
  };

  handleCountryChange = (selectedOption, formData, setFormData) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ...updatedState,
      },
    }));
  };

  handleProvinceChange = (selectedOption) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedProvince: selectedOption,
      },
    }));
  };

  getDuplicateInfo = async (email, phoneNumber) => {
    // Example implementation assuming you have a backend endpoint to check for duplicates
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/check-duplicates`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, phoneNumber }),
      }
    );
    const data = await response.json();

    return data;
  };

  checkPhoneNumberForDuplicate = (phoneNumber, data) => {
    if (!phoneNumber) {
      return false;
    }

    if (this.props.attendee.phone_number === phoneNumber) {
      return false;
    }

    return data.duplicatePhoneNumber;
  };

  checkEmailForDuplicate = (email, data) => {
    if (this.props.attendee.email === email) {
      return false;
    }

    return data.duplicateEmail;
  };

  checkForDuplicates = async (email, phoneNumber) => {
    const data = await this.getDuplicateInfo(email, phoneNumber);

    const phoneNumberResult = this.checkPhoneNumberForDuplicate(
      phoneNumber,
      data
    );
    const emailResult = this.checkEmailForDuplicate(email, data);

    if (emailResult) {
      this.setState({
        errorEmail:
          "Need a unique email. Someone else has already been registered with this email.",
      });
    } else {
      this.setState({ errorEmail: "" });
    }

    if (phoneNumberResult) {
      this.setState({
        errorPhoneNumber:
          "Need a unique phone number. Someone else has already been registered with this phone number.",
      });
    } else {
      this.setState({ errorPhoneNumber: "" });
    }

    return emailResult || phoneNumberResult;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.updateIsLoading(true);
    const { formData } = this.state;

    if (!formData.email) {
      if (!formData.email) {
        this.setState({ errorEmail: "Please enter a valid email" });
      }

      this.props.updateIsLoading(false);

      return;
    }

    const duplicateError = await this.checkForDuplicates(
      formData.email,
      formData.phoneNumber
    );
    if (duplicateError) {
      const emailSection = document.getElementById("email-section");
      emailSection.scrollIntoView({ behavior: "smooth", block: "start" });
      this.props.updateIsLoading(false);
      return;
    }

    try {
      // Create a copy of formData
      const formDataCopy = { ...formData };

      // Add attendee_id to formDataCopy
      formDataCopy.selectedCountry = formDataCopy.selectedCountry.label;
      formDataCopy.selectedProvince = formDataCopy.selectedProvince.label;

      // Assuming you have an API endpoint to update attendee details
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/attendees`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataCopy),
        }
      );

      if (response.ok) {
        this.props.setActiveAttendeeForm(-1);

        this.resetForm();
        this.props.fetchData();
      } else {
        // Attendee details update failed
        console.error("Failed to update attendee details");
        // Optionally, handle error state or show a message to the user
      }
    } catch (error) {
      console.error("Error updating attendee details:", error);
      // Optionally, handle error state or show a message to the user
    }

    this.props.updateIsLoading(false);
  };

  resetForm = () => {
    this.setState({
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        selectedProvince: provinceOptions[0],
        selectedCountry: countryOptions[0],
        provinceLabel: "Province",
        provinceOptions: provinceOptions,
        zip: "",
        zipLabel: "Postal Code",
        dietary: "",
        moreNeeds: "",
      },
    });
  };

  render() {
    const { formData } = this.state;

    return (
      <>
        <div className="col-md-12">
          <h4 style={{ color: "#2dccd3" }}>
            Register Attendee {this.props.index + 1}
          </h4>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="col-lg-6" id="email-section">
          <div className="form-group">
            <label htmlFor="email">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={formData.email}
              onChange={this.handleInputChange}
              required={true}
            />
            {this.state.errorEmail && (
              <p className="error-message">{this.state.errorEmail}</p>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={this.handleInputChange}
            />
            {this.state.errorPhoneNumber && (
              <p className="error-message">{this.state.errorPhoneNumber}</p>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="addressLine1">Address</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="addressLine1"
                placeholder="Address Line 1"
                value={formData.addressLine1}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="addressLine2"
              placeholder="Address Line 2"
              value={formData.addressLine2}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              value={formData.city}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="province">{formData.provinceLabel}</label>
            <Select
              id="province"
              defaultValue={formData.selectedProvince}
              value={formData.selectedProvince}
              className="form-control"
              options={formData.provinceOptions}
              styles={DROPDOWN_STYLE}
              onChange={this.handleProvinceChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="zip">{formData.zipLabel}</label>
            <input
              type="text"
              className="form-control"
              id="zip"
              placeholder={"Your " + formData.zipLabel}
              value={formData.zip}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <Select
              id="country"
              defaultValue={formData.selectedCountry}
              value={formData.selectedCountry}
              className="form-control"
              options={countryOptions}
              styles={DROPDOWN_STYLE}
              onChange={this.handleCountryChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="dietary">
              Do they have any dietary restrictions?
            </label>
            <input
              type="text"
              className="form-control"
              id="dietary"
              placeholder="Dietary Restrictions"
              value={formData.dietary}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="moreNeeds">
              Do you have any other requirements?
            </label>
            <input
              type="text"
              className="form-control"
              id="moreNeeds"
              placeholder="Additional Requests"
              value={formData.moreNeeds}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <button
          className="btn btn-theme effect btn-md"
          type="submit"
          onClick={this.handleSubmit}
        >
          Register Attendee
        </button>
      </>
    );
  }
}

export default AttendeeForm;