import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplePayButton from "./ApplePayButton";
import GooglePayButton from "./GooglePayButton";
import {
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./sarahandcam_constants";
import AddressForm from "./AddressForm";
import ContactForm from "./ContactForm";
import BillingForm from "./BillingForm";
import { useLocation } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import createIntentPayment from "../services/tickets_payment_intent";
import createOtherIntentPayment from "../services/tickets_other_pay_payment_intent";
import { calculateTotalAmount } from "../services/paymentUtils";
import {
  handleCountryChange as handleCountryChangeUtil,
  handleBillingCountryChange as handleBillingCountryChangeUtil,
  tooltip,
  formatAsCurrency,
} from "../services/formUtils";
import SarahAndCamTicketForm from "./SarahAndCamTicketForm";
import TicketNameForm from "./TicketNameForm";


const SarahAndCamBuyTicketsForm = ({
  updateIsLoading,
  updateHasDonated,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateRegistrationURL,
  selectedTicketQuantityOption,
  ticketQuantityOptions,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const getAmount = (selectedTicketQuantityOption, selectedTicketOption) => {
    return selectedTicketQuantityOption.value * selectedTicketOption.cost;
  };

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      requestPayerPhone: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 750,
      },
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        setApplePay(result.applePay);
        setGooglePay(result.googlePay);
      }
    });

    pr.on("paymentmethod", async (e) => {
      await createOtherIntentPayment(
        stripe,
        updateFirstName,
        getAmount(selectedTicketQuantityOption, selectedTicketOption),
        payFees,
        e,
        toast,
        updateHasDonated,
        formData,
        selectedTicketQuantityOption.value,
        selectedTicketOption.value,
        updateRegistrationURL
      );
    });
  }, [
    stripe,
    elements,
    payFees,
    updateFirstName,
    selectedTicketQuantityOption,
    selectedTicketOption,
    updateRegistrationURL,
    formData,
  ]);

  const updatePaymentRequestInstance = async (event) => {
    paymentRequest.update({
      country: "CA",
      currency: "cad",
      total: {
        label: "Purchase Tickets to Big Sisters' Event",
        amount:
        parseInt(calculateTotalAmount(
            getAmount(selectedTicketQuantityOption, selectedTicketOption),
            payFees,
            formData
          ) * 100),
      },
    });
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleChoiceUpdate = (option, label) => {
    setFormData({
      ...formData,
      [label]: option.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    await createIntentPayment(
      stripe,
      cardElement,
      updateFirstName,
      getAmount(selectedTicketQuantityOption, selectedTicketOption),
      payFees,
      formData,
      updateHasDonated,
      toast,
      setFormData,
      updateIsLoading,
      selectedTicketQuantityOption.value,
      selectedTicketOption.value,
      updateRegistrationURL
    );
  };

  const handleCountryChange = (selectedOption) => {
    handleCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingCountryChange = (selectedOption) => {
    handleBillingCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  const handleRealButtonClick = () => {
    updatePaymentRequestInstance();
    paymentRequest.show();
  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h3 style={{ color: "#2dccd3", marginBottom: "7px" }}>GrapeJuice 2024 Tickets</h3>
          <p style={{ color: "#232323" }}>
            Code: sarahandcam
          </p>
          <div className="row  text-light">
            <SarahAndCamTicketForm
              handleInputChange={handleInputChange}
              formData={formData}
              options={ticketOptions}
              selectedTicketOption={selectedTicketOption}
              getAmount={getAmount}
              updateTicketOption={updateTicketOption}
              selectedTicketQuantityOption={selectedTicketQuantityOption}
              ticketQuantityOptions={ticketQuantityOptions}
            />

            <div className="col-md-12">
              <div className="form-group">
                <div className="d-flex flex-wrap align-items-center">
                  <label
                    style={{ display: "flex", alignItems: "center" }}
                    className="coverFeeOption"
                  >
                    <input
                      type="checkbox"
                      onChange={togglePayFees}
                      checked={payFees}
                      style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                    />
                    &nbsp;&nbsp;I will generously cover the&nbsp;
                    <span style={{ fontWeight: "bold" }}>transaction fees</span>
                    .&nbsp;
                    <OverlayTrigger placement="right" overlay={tooltip}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ color: "#2dccd3" }}
                      />
                    </OverlayTrigger>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                {payFees &&
                  getAmount(
                    selectedTicketQuantityOption,
                    selectedTicketOption
                  ) > 0 && (
                    <>
                      <p style={{ color: "black" }}>
                        Your payment will come to{" "}
                        {formatAsCurrency(
                          calculateTotalAmount(
                            getAmount(
                              selectedTicketQuantityOption,
                              selectedTicketOption
                            ),
                            payFees,
                            formData
                          )
                        )}
                        .
                      </p>
                    </>
                  )}
              </div>
            </div>

            {/* 
<SingleChoiceDropdown
  value={formData.attendEvent}
  toggleValue={(e) => toggleValue(e, "attendEvent")}
  label="Are you attending GrapeJuice?"
  identifier="attendEvent"
  outerClass="col-md-12"
/>
*/}



            {applePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="ApplePay">Pay by Apple Pay</label>
                  <div className="input-group">
                    <ApplePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            {googlePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="GooglePay">Pay by Google Pay</label>
                  <div className="input-group">
                    <GooglePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            <TicketNameForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
              creditCardSectionTitle={
                applePay || googlePay
                  ? "Or Credit Card Payment"
                  : "Credit Card Payment"
              }
            />

            <ContactForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="cardNumber">
                  Pay by Credit Card <span className="required">*</span>
                </label>
                <div className="input-group">
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>

            <AddressForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <BillingForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <button className="btn btn-theme effect btn-md" type="submit">
              Buy Tickets Now
            </button>

            <div className="question">
              <div className="icon">
                <i className="fas fa-phone"></i>
              </div>

              <div className="info">
                <h5 style={{ color: "rgb(45, 204, 211)" }}>
                  Have any questions about your GrapeJuice 2024 ticket(s)
                  purchase?
                </h5>
                <span style={{ color: "#232323" }}>
                  Call Now: 604.873.4525 ext 108
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SarahAndCamBuyTicketsForm;