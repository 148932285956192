import React from "react";
import CustomChoiceDropdown from "./CustomChoiceDropdown";

class MoreNeedsForm extends React.Component {
  render() {
    const { formData, handleInputChange } =
      this.props; // Destructure props


    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="moreNeeds">Do you have any other requirements?</label>
            <input
              type="text"
              className="form-control"
              id="moreNeeds"
              placeholder="Your additional requirements"
              value={formData.moreNeeds}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default MoreNeedsForm;
