import React from "react";
import CustomChoiceDropdown from "./CustomChoiceDropdown";
import { formatAsCurrency } from "../services/formUtils";

class BigSistersTicketForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      options,
      ticketQty,
      selectedTicketOption,
      updateTicketQuantity,
      getAmount,
      updateTicketOption,
      selectedTicketQuantityOption,
      ticketQuantityOptions
    } = this.props; // Destructure props

    return (
      <>
        <CustomChoiceDropdown
          value={selectedTicketQuantityOption}
          handleChoiceUpdate={updateTicketOption}
          label={"How many tickets?"}
          identifier="selectedTicketQuantityOption"
          choiceOptions={ticketQuantityOptions}
          defaultValue={selectedTicketQuantityOption}
          isRequired={true}
          outerClass="col-md-12"
        />
        <div className="col-md-12">
          <div className="form-group">
            <div className="d-flex flex-wrap align-items-center">
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="coverFeeOption"
              >
                &nbsp;&nbsp;Your ticket subtotal is:&nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {formatAsCurrency(getAmount(selectedTicketQuantityOption, selectedTicketOption))}
                </span>
                &nbsp;
              </label>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BigSistersTicketForm;
